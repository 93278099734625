import React from 'react'

const Privecy = React.lazy(() => import('./Components/Large/Policy/Privecy')) 
const ContactUS = React.lazy(() => import('./Components/Large/ContactUS/ContactUS'))
const AboutUs = React.lazy(() => import('./Components/Large/AboutUS/AboutUs'))
const PaymentReturn = React.lazy(() => import('./Components/Large/Policy/PaymentReturn'))
const TermsCondition = React.lazy(() => import('./Components/Large/Policy/TermsCondition'))
const Shipping = React.lazy(() => import('./Components/Large/Policy/Shipping'))
const Sitemap = React.lazy(() => import('./Others/Sitemap'))
const Jobs = React.lazy(() => import('./Others/Jobs'))
const OrderFailed = React.lazy(() => import('./Others/OrderFailed'))
const ThankYou = React.lazy(() => import('./Others/ThankYou'))
const Login = React.lazy(() => import('./Components/Login/Login'))
const Wishlist = React.lazy(() => import('./Components/Large/WishList/Wishlist'))
const Smallwishlist = React.lazy(() => import('./Components/Small/WishList/Wishlist'))
const Cart = React.lazy(() => import('./Components/Large/Cart/Cart'))
const History = React.lazy(() => import('./Components/Large/History/History'))
const Forgotpassword = React.lazy(() => import('./Components/Login/ForgotPassword'))
const Otpverification = React.lazy(() => import('./Components/Login/Otpverification'))
const Orderhistory = React.lazy(() => import('./Components/Large/History/Orderhistory'))
const Track = React.lazy(() => import('./Components/Large/History/Track'))
const HistoryDetails = React.lazy(() => import('./Components/Large/History/Historydetails'))
const Invoice = React.lazy(() => import('./Components/Large/History/Invoice'))
const Product = React.lazy(() => import('./Components/Large/Product/Product'))
const ProductVariant = React.lazy(() => import('./Components/Large/LandingPage/Controler/ProductVariant'))
const ProductByCategory = React.lazy(() => import('./Components/Large/CategoryPage/ProductByCategory'))
const PaymentLoading=React.lazy(()=>import('./Others/PaymentLoading'))
const Unsubscribe=React.lazy(()=>import('./Others/Unsubscribe'))
const ProductByCategorySmall=React.lazy(()=>import('./Components/Small/CategoryPage/ProductByCategory'))
const LandingPage= React.lazy(()=>import('./Components/Large/LandingPage/LandingPage'))
const Home= React.lazy(()=>import('./Components/Large/LandingPage/Home/Home'))
const SmallHome= React.lazy(()=>import('./Components/Small/LandingPage/Home/Homesmall'))
const AllCategory= React.lazy(()=>import('./Components/Large/CategoryPage/AllCategory'))
 
export {LandingPage,Home,SmallHome,Privecy,ContactUS,AboutUs,PaymentReturn,TermsCondition,Shipping,Sitemap,Jobs,OrderFailed,ThankYou,Login,Forgotpassword,Otpverification,Wishlist,Smallwishlist,Cart,History,Orderhistory,Track,HistoryDetails,Invoice,Product,ProductVariant,ProductByCategory,PaymentLoading,Unsubscribe,ProductByCategorySmall,AllCategory}  